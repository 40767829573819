.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.template-forms-others-service {
  padding: var(--groupui-spacing-10);
}

.template-forms-others-service groupui-card {
  max-width: 1200px;
}

.template-forms-others-service .sub-headings {
  margin-bottom: var(--groupui-spacing-5);
  margin-top: var(--groupui-spacing-9);
}

.template-forms-others-service #description {
  margin-top: var(--groupui-spacing-3);
}

.template-forms-others-service .button-col {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--groupui-spacing-9);
}

.template-forms-others-service .button-col groupui-button:first-of-type {
  margin-right: var(--groupui-spacing-5);
}

.stepper-vertical-live-demo {
  padding: 72px 48px;
  height: 360px;
}

.checkbox-divider {
  margin: 0 0 10px;
}

.checkbox groupui-checkbox {
  margin: 0 0 5px;
}

.checkbox-sub groupui-checkbox {
  margin: 0 0 5px 24px;
}

.buttongroup-radiobutton {
  color: red !important;
}

.warning-text {
  color: #da0c1f;
  font-size: 14px;
  font-weight: normal;
}

.noHover {
  pointer-events: none;
}